import React from 'react';

interface Props extends Omit<React.HTMLProps<HTMLImageElement>, 'src' | 'alt' | 'crossOrigin'>{}

const PlaneIcon = (props: Props) => {
  return (
    <img {...props} src={require('../../images/plane.svg').default} alt="Plane Icon" />
  );
};

export default PlaneIcon;
